import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@shared/@material/material.module';
import { BuildingDetailService } from '@shared/models/building-detail/building-detail.service';
import { BuildingService } from '@shared/models/building/building.service';
import { CommuneService } from '@shared/models/commune/commune.service';
import { ParcelService } from '@shared/models/parcel/parcel.service';
import { LoaderComponent } from './component/loader/loader.component';
import { SearchComponent } from './component/search/search.component';
import { SvgIconDirective } from './directive/svg-icon.directive';

@NgModule({
  imports: [MaterialModule, CommonModule, RouterModule, ReactiveFormsModule, FlexLayoutModule],
  declarations: [LoaderComponent, SvgIconDirective, SearchComponent],
  exports: [LoaderComponent, MaterialModule, SvgIconDirective, SearchComponent],
  providers: [ParcelService, BuildingService, CommuneService, BuildingDetailService],
})
export class SharedModule {}
