import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Logger } from '@core';
import { first } from 'rxjs/operators';

const logger = new Logger('SvgIconDirective');

type Color = 'primary' | 'accent' | 'warn' | 'primary-A400';
type SizeAlias = 'x-small' | 'small' | 'budicon' | 'regular' | 'big' | 'large' | 'x-large';
const PREFIX_STROKE = 'svg-stroke-';
const PREFIX_FILL = 'svg-fill-';
const PREFIX_SIZE = 'svg-size-';

@Directive({
  selector: '[appSvgIcon]',
})
export class SvgIconDirective implements OnInit {
  @Input() name: string;
  @Input() fill: Color | undefined;
  @Input() stroke: Color | undefined;
  @Input() size: SizeAlias | number | undefined;
  el: ElementRef;

  constructor(el: ElementRef, private http: HttpClient, private renderer: Renderer2) {
    this.el = el;
  }

  ngOnInit() {
    this.size = this.size ? this.size : 'budicon';
    this.http
      .get(`${environment.assetsUrl}/svg/${this.name}.svg`, { responseType: 'text' })
      .pipe(first())
      .subscribe((response: any) => {
        let svgDomElement = this._svgElementFromString(response);
        svgDomElement = this._addClassesToSvgElement(svgDomElement);
        const titleTag = svgDomElement.getElementsByTagName('title').item(0);
        if (titleTag) {
          titleTag.remove();
        }
        this.renderer.appendChild(this.el.nativeElement, svgDomElement);
      });
  }

  private _svgElementFromString(str: string): SVGElement | never {
    const div = this.renderer.createElement('DIV');
    div.innerHTML = str;
    const svg = div.querySelector('svg') as SVGElement;
    if (!svg) {
      throw new Error('No SVG found in loaded contents');
    }

    return svg;
  }

  private _addClassesToSvgElement(element: SVGElement): SVGElement {
    const classes: string[] = [];
    if (this.fill) {
      classes.push(PREFIX_FILL + this.fill);
    }
    if (this.stroke) {
      classes.push(PREFIX_STROKE + this.stroke);
    }
    if (this.size) {
      classes.push(PREFIX_SIZE + this.size);
    }
    classes.forEach((cssClass) => {
      element.classList.add(cssClass);
    });

    return element;
  }
}
