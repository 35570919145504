import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Logger } from '@core';
import { Feature, FeatureCollection, GeoJsonObject, GeometryObject } from 'geojson';
import { divIcon, GeoJSON, geoJSON, latLng, LatLng, LatLngBounds, Layer, Map, Marker, marker } from 'leaflet';
import 'leaflet-textpath';
import { maxBy, minBy } from 'lodash';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, first, map, switchMap, throttleTime } from 'rxjs/operators';

const ZOOM_CONTROL = {
  PARCEL: 17,
  BUILDINGS: 17,
  SUBSIDENCE_ISOLINE_LABELS: 17,
  DEM_LABELS: 17,
  SUBSIDENCE_INFO: 14,
};

export enum SUBSIDENCE_TYPE {
  NONE = 0,
  CURRENT,
  PREDICT,
  HISTORY,
}

export enum COLORS {
  CURRENT = '175, 63, 236',
  PREDICT = '255, 104, 104',
  HISTORY = '67, 112, 201',
}

const logger = new Logger('MapSharingService');

@Injectable({
  providedIn: 'root',
})
export class MapSharingService {
  bounds$ = new Subject<LatLngBounds>();
  zoom$ = new Subject<number>();
  center$ = new Subject<LatLng>();
  loading$ = new BehaviorSubject(false);
  mapQuery: FormGroup;
  selectedParcelId = -1;
  maxOpacity: number;
  buildingLayer: GeoJSON = geoJSON(null, {
    style: (feature) => ({
      weight: 1,
      stroke: true,
      color: '#33B804',
      fillColor: 'rgba(104,187,75,0.5)',
      className: 'events-none',
    }),
  });
  map: Map;
  subsidenceLayer: GeoJSON = geoJSON(null, {
    style: (feature) => ({
      weight: 1,
      stroke: feature.geometry.type !== 'Polygon',
      dashArray: [4, 4],
      color: this.getSubsidenceColor(),
      fillColor: this.calculateSubsidenceOpacity(feature),
      fillOpacity: 0.7,
      className: 'subsidence-isoline events-none',
    }),
  });
  parcelLayer: GeoJSON = geoJSON(null, {
    style: (feature) => ({
      weight: this.selectedParcelId === feature.properties.id ? 2 : this.map.getZoom() <= ZOOM_CONTROL.PARCEL ? 0 : 1,
      stroke: true,
      color: '#F9BC3D',
      fillColor: '#F3A80C',
      fillOpacity: this.selectedParcelId === feature.properties.id ? 0.32 : 0.05,

      // opacity: this.selectedParcelId === feature.properties.id && this.selectedParcelId !== -1 ? 1: 0
    }),
    onEachFeature: (feature: Feature<GeometryObject, any>, layer: Layer): void => {
      // @ts-ignore
      feature.properties.bounds = layer.getBounds();
      // feature.properties.zoom = this.map.getZoom()
    },
  });
  mineLayer: GeoJSON = geoJSON(null, {
    style: (feature) => ({
      weight: 1,
      stroke: true,
      dashArray: [4, 4],
      color: '#335393',
      fillColor: 'rgba(51,83,147,0.15)',
      className: 'events-none',
    }),
  });
  demLayer: GeoJSON = geoJSON(null, {
    style: (feature) => ({
      weight: 1,
      stroke: true,
      dashArray: [4, 4],
      color: '#c1c1c1',
      fillColor: '#c1c1c1',
      fillOpacity: 0.5,
      className: 'events-none',
    }),
  });
  selectedCommuneLayer: GeoJSON;
  snackBarRef: MatSnackBarRef<any>;
  COLORS: COLORS;

  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private router: Router,
    private fB: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.setMapQuery();
    this.parcelLayer.on('click', (event) => {
      const feature = event.layer.feature;
      this.selectedParcelId = feature.properties.id;
      // this.map.fitBounds(feature.properties.bounds);
      this.zone.run(() => {
        this.router.navigate([`/home/detail/${feature.properties.id}`]);
      });
    });
  }

  get ZOOM_CONTROL() {
    return ZOOM_CONTROL;
  }

  setMap(imap: Map) {
    this.map = imap;
    this.map.addLayer(this.mineLayer);
    this.map.addLayer(this.subsidenceLayer);
    this.map.addLayer(this.parcelLayer);
    this.map.addLayer(this.buildingLayer);
    this.router.events.pipe(throttleTime(500)).subscribe((e) => {
      this.map.invalidateSize();
    });
  }

  setMapQuery() {
    // this.loading$.next(true);
    this.mapQuery = this.fB.group({
      latMin: [],
      latMax: [],
      lonMin: [],
      lonMax: [],
      center: [[50.232, 18.761]],
      buildings: [false],
      subsidence: [SUBSIDENCE_TYPE.NONE],
      subsidence_date: [null],
      parcels: [false],
      mines: [false],
      dem: [false],
      zoom: [13],
      epsg: [4326],
    });

    // INFO: this is one time load
    this.mapQuery.valueChanges
      .pipe(
        first(),
        switchMap((p) => this.http.get('/mines', { params: { epsg: p.epsg } }))
      )
      .subscribe((mines: FeatureCollection) => {
        this.mineLayer.clearLayers();
        this.mineLayer.addData(mines);
        this.map.fitBounds(this.mineLayer.getBounds());
      });

    const setTextOnDem = () => {
      this.demLayer.eachLayer((layer: any) => {
        if (layer.feature.geometry.type !== 'Polygon') {
          if (layer.feature.geometry.coordinates) {
            const labelMarker: Marker = marker(
              latLng(layer.feature.geometry.coordinates[0][1], layer.feature.geometry.coordinates[0][0]),
              {
                icon: divIcon({
                  className: 'dem-label',
                  html: `${layer.feature.properties.value}m`,
                }),
              }
            );
            labelMarker.addTo(this.demLayer);
            if (this.map.getZoom() < ZOOM_CONTROL.DEM_LABELS) {
              labelMarker.setOpacity(0);
            }
          }
        }
      });
    };
    const mapQueryChanges = this.mapQuery.valueChanges.pipe(debounceTime(600));

    mapQueryChanges
      .pipe(
        switchMap((params) => {
          this.loading$.next(true);
          logger.info('LOADING');
          const sources = {
            buildings:
              params.buildings && params.zoom >= ZOOM_CONTROL.BUILDINGS
                ? this.http.get('/buildings', { params })
                : of(null),
            parcels:
              params.parcels && params.zoom >= ZOOM_CONTROL.PARCEL ? this.http.get('/parcels', { params }) : of(null),
            dem: params.dem ? this.http.get('/dem-lines', { params }) : of(null),
            subsidence: of(null),
          };
          switch (params.subsidence as SUBSIDENCE_TYPE) {
            case SUBSIDENCE_TYPE.CURRENT:
              sources.subsidence = this.getMergedIsoLines('/subsidence-sum-isolines', params, false);
              break;
            case SUBSIDENCE_TYPE.PREDICT:
              params.subsidence_date = false;
              sources.subsidence = this.getMergedIsoLines('/predicted-subsidence-isolines', params, false);
              break;
            case SUBSIDENCE_TYPE.HISTORY:
              sources.subsidence = this.getMergedIsoLines('/subsidence-isolines', params, params.subsidence_date);
          }

          return forkJoin(sources); // .pipe(finalize(() => ))
        })
      )
      .subscribe(
        (layers: {
          buildings: FeatureCollection;
          parcels: FeatureCollection;
          subsidence: FeatureCollection;
          dem: FeatureCollection;
        }) => {
          this.loading$.next(false);
          this.buildingLayer.clearLayers();
          this.parcelLayer.clearLayers();
          this.demLayer.clearLayers();

          if (layers.dem) {
            this.demLayer.addData(layers.dem);
          }

          if (layers.parcels) {
            this.parcelLayer.addData(layers.parcels);
            this.buildingLayer.bringToFront();
          }

          if (layers.buildings) {
            this.buildingLayer.addData(layers.buildings);
            this.buildingLayer.bringToFront();
          }
          this.handleSubsidence(layers.subsidence);
          this.toggleSubsidenceLabelMarkers();
          this.toggleDemLabelMarkers();
          setTextOnDem();
        }
      );

    this.loading$.subscribe((v) => {
      this.zone.run(() => {
        if (!this.map) {
          return;
        }
        if (v) {
          logger.error('SHOULD DISABLE');
          this.map.dragging.disable();
          this.map.scrollWheelZoom.disable();
        } else {
          this.map.scrollWheelZoom.enable();
          this.map.dragging.enable();
        }
      });
    });
  }

  setTextOnSubsidence() {
    this.subsidenceLayer.eachLayer((layer: any) => {
      if (layer.feature.geometry.type !== 'Polygon') {
        if (layer.feature.geometry.coordinates) {
          const labelMarker: Marker = marker(this.getMarkerPosition(layer), {
            icon: divIcon({
              className: 'isoline-label',
              html: `${layer.feature.properties.value}cm`,
            }),
          });
          labelMarker.addTo(this.subsidenceLayer);
          if (this.map.getZoom() < ZOOM_CONTROL.SUBSIDENCE_ISOLINE_LABELS) {
            labelMarker.setOpacity(0);
          }
        }
      }
    });
  }

  getMergedIsoLines(url: string, params: any, date?: string | boolean) {
    // TODO: smarter joining of params
    const polyParams: any = {
      ...params,
      geometry: 'polygon',
    };
    const isoParams: any = {
      ...params,
    };
    if (date) {
      isoParams.date = date;
      polyParams.date = date;
    }
    return forkJoin([
      this.http.get(url, {
        params: polyParams,
      }),
      this.http.get(url, {
        params: isoParams,
      }),
    ]).pipe(
      catchError(() => of(undefined)),
      map((i: any) => {
        if (!i) {
          return [];
        }
        i[1].features = [...i[1].features, ...i[0].features];
        return i[1];
      })
    );
  }

  setSubsidenceLayer(layer: GeoJsonObject) {
    this.subsidenceLayer.clearLayers();
    // @ts-ignore
    if (layer.features.length === 0) {
      return;
    }
    // @ts-ignore

    // @ts-ignore
    const maxValueFeature: Feature = maxBy(layer.features, (f) => parseInt(f.properties.value, 10));
    const maxOpacity = maxValueFeature.properties.value;
    // @ts-ignore
    const minValueFeature: Feature = minBy(layer.features, (f) => parseInt(f.properties.value, 10));
    // const minOpacity = minValueFeature.properties.value;
    this.maxOpacity = maxOpacity;
    this.subsidenceLayer.addData(layer);
    this.setTextOnSubsidence();
  }

  navigateToCoordinate(commune: GeoJsonObject) {
    logger.info('navigateToCoordinate', commune);
    this.selectedCommuneLayer?.clearLayers();
    this.selectedCommuneLayer = geoJSON(commune, {
      style: (feature) => ({
        weight: 1,
        stroke: true,
        color: '#F9BC3D',
        fillColor: '#F3A80C',
        dashArray: [4, 4],
      }),
    });
    this.map.addLayer(this.selectedCommuneLayer);
    this.bounds$.next(this.selectedCommuneLayer.getBounds());
  }

  private calculateSubsidenceOpacity(feature: Feature<GeometryObject, any>) {
    const fOpacity =
      parseInt(feature.properties.value, 10) === 0 ? 0 : parseInt(feature.properties.value, 10) / this.maxOpacity;
    return feature.geometry.type === 'Polygon' ? this.getSubsidenceColor(fOpacity) : 'transparent';
  }

  private toggleSubsidenceLabelMarkers() {
    if (this.map) {
      const opacity = this.map.getZoom() >= ZOOM_CONTROL.SUBSIDENCE_ISOLINE_LABELS ? 1 : 0;
      this.subsidenceLayer.eachLayer((layer: Layer) => {
        if (layer instanceof Marker) {
          layer.setOpacity(opacity);
        }
      });
    }
  }

  private toggleDemLabelMarkers() {
    if (this.map) {
      const opacity = this.map.getZoom() >= ZOOM_CONTROL.DEM_LABELS ? 1 : 0;
      this.demLayer.eachLayer((layer: Layer) => {
        if (layer instanceof Marker) {
          layer.setOpacity(opacity);
        }
      });
    }
  }

  private getSubsidenceColor(opacity: number = 0.7): string {
    switch (this.mapQuery.get('subsidence').value as SUBSIDENCE_TYPE) {
      case SUBSIDENCE_TYPE.CURRENT:
        return `rgba(${COLORS.CURRENT}, ${opacity})`;
      case SUBSIDENCE_TYPE.PREDICT:
        return `rgba(${COLORS.PREDICT}, ${opacity})`;
      case SUBSIDENCE_TYPE.HISTORY:
        return `rgba(${COLORS.HISTORY}, ${opacity})`;
      default:
        return `rgba(${COLORS.CURRENT}, ${opacity})`;
    }
  }

  private handleSubsidence(subLayer: any) {
    this.subsidenceLayer.clearLayers();
    if (subLayer?.features?.length > 0) {
      if (subLayer) {
        this.setSubsidenceLayer(subLayer);
      }
    } else {
      if (this.mapQuery.get('subsidence').value === SUBSIDENCE_TYPE.HISTORY) {
        this._snackBar.open('Dla zadanego obszaru nie posiadamy danych. Spróbuj oddalić mapę lub zmienić datę.', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    }
  }

  private getMarkerPosition(layer: any): LatLng {
    switch (layer?.feature?.geometry?.type) {
      case 'MultiPolygon':
        return latLng(layer.feature.geometry.coordinates[0][0][0][1], layer.feature.geometry.coordinates[0][0][0][0]);
      case 'Polygon':
      case 'MultiLineString':
        return latLng(layer.feature.geometry.coordinates[0][0][1], layer.feature.geometry.coordinates[0][0][0]);
      case 'LineString':
      case 'MultiPoint':
        return latLng(layer.feature.geometry.coordinates[0][1], layer.feature.geometry.coordinates[0][0]);
      case 'Point':
        return latLng(layer.feature.geometry.coordinates[0], layer.feature.geometry.coordinates[1]);
      default:
        console.log('Can`t get marker position from layer: ', layer);
        return latLng(layer.feature.geometry.coordinates[0][1], layer.feature.geometry.coordinates[0][0]);
    }
  }
}
