import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Model, ModelFactory } from '@angular-extensions/model';
import { HttpClient } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Feature } from 'geojson';
import { Logger } from '@core';

const logger = new Logger('BuildingDetailService');

@Injectable({
  providedIn: 'root',
})
export class BuildingDetailService {
  buildingDetail$: Observable<BuildingDetail>;
  loading$ = new BehaviorSubject(false);

  private model: Model<BuildingDetail>;

  constructor(private modelFactory: ModelFactory<BuildingDetail>, private http: HttpClient) {
    this.model = this.modelFactory.create(null);
    this.buildingDetail$ = this.model.data$;
  }

  getBuilding(buildingId: string | number) {
    this.loading$.next(true);
    return this.http.get(`/buildings/${buildingId}`, { params: { epsg: '4326' } }).pipe(
      finalize(() => this.loading$.next(false)),
      tap((buildingDetail: BuildingDetail) => {
        this.model.set(buildingDetail);
      })
    );
  }
}

export interface BuildingDetail extends Feature {
  properties: {
    id: number;
    parcel_ids: number[];
    center: number[];
  };
}
