import { Model, ModelFactory } from '@angular-extensions/model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ParcelDetail } from '@app/@shared/models/parcel-detail.service';
import { CredentialsService } from '@core/auth/credentials.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, debounceTime, finalize, retry } from 'rxjs/operators';

const initialData: Cart = null;

@Injectable({
  providedIn: 'root',
})
export class CartService {
  loading$ = new BehaviorSubject(false);
  saving$ = new BehaviorSubject(false);
  cart$: Observable<Cart>;
  private model: Model<Cart>;

  constructor(
    private modelFactory: ModelFactory<Cart>,
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private credentialsService: CredentialsService
  ) {
    this.model = this.modelFactory.create(initialData);
    this.cart$ = this.model.data$;
    const savedCartToken = localStorage.getItem(cartTokenKey);
    if (savedCartToken) {
      this._token = savedCartToken;
      this.getCart(savedCartToken);
    }
  }

  private _token: string;

  get token(): string | null {
    return this._token;
  }

  hasToken(): boolean {
    return !!this.token;
  }

  setToken(token: string) {
    this._token = token || null;

    if (token) {
      localStorage.setItem(cartTokenKey, token);
    } else {
      localStorage.removeItem(cartTokenKey);
    }
  }

  updateProp(newPropValue: string) {
    const cart = this.model.get();

    this.model.set(cart);
  }

  getCart(token: string) {
    this.loading$.next(true);
    this.http
      .get(`/cart/${token}`)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(
        (response: any) => {
          this.model.set(response.data);
        },
        (err) => {
          this.setToken(null);
          this.credentialsService.setCredentials(null);
        }
      );
  }

  add(productType: ProductType, parcelId: number) {
    this.loading$.next(true);

    // If no cart then create cart with the item
    if (this.hasToken()) {
      const data = {
        parcel_id: parcelId,
        product_type: productType,
      };
      const cold = this.http.post(`/cart/${this.token}/items`, data).pipe(
        catchError((e) => {
          this.model.set(null);
          this.setToken(null);
          this.credentialsService.setCredentials(null);
          throw e;
        }),
        retry(2), // INFO: If admin, retry call after logout
        finalize(() => this.loading$.next(false))
      );

      cold.subscribe(
        (response: any) => {
          this.model.set(response.data);
          this.setToken(response.data.token);
        },
        (error: HttpErrorResponse) => {}
      );
      return cold.pipe(debounceTime(500));
    } else {
      const data = {
        items: [
          {
            parcel_id: parcelId,
            product_type: productType,
          },
        ],
      };
      const cold = this.http.post(`/cart`, data).pipe(
        catchError((e) => {
          this.model.set(null);
          this.setToken(null);
          this.credentialsService.setCredentials(null);
          throw e;
        }),
        retry(2), // INFO: If admin, retry call after logout
        finalize(() => this.loading$.next(false))
      );

      cold.subscribe(
        (response: any) => {
          this.model.set(response.data);
          this.setToken(response.data.token);
        },
        (error: HttpErrorResponse) => {}
      );
      return cold.pipe(debounceTime(500));
    }
  }

  deleteItemFromCart(itemId: number) {
    if (!this.hasToken()) {
      return;
    }

    this.loading$.next(true);
    this.http
      .delete(`/cart/${this.token}/items/${itemId}`)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(
        (response: any) => {
          this.model.set(response.data);
        },
        (err) => {
          // TODO: handle error while deleting cart item
          alert('Could not delete the item');
        }
      );
  }

  checkout(data: any): Observable<any> {
    if (!this.hasToken()) {
      return;
    }

    this.saving$.next(true);

    return this.http.post(`/cart/${this.token}/checkout`, data).pipe(finalize(() => this.saving$.next(false)));
  }

  complete(checkoutResponse: any) {
    // Remove token and set Cart as null
    // Cart is no longer available, order is
    this.setToken(null);
    this.model.set(initialData);

    // alert(checkoutResponse.message);
    const order = checkoutResponse.data;

    // If no need to pay just redirect to thank you page
    if (checkoutResponse.meta.payment_redirect_url === null) {
      this.router.navigate([`/order/${order.token}/thank-you`]);
      return;
    }

    // if has payment redirect url should redirect to payment website
    window.location.href = checkoutResponse.meta.payment_redirect_url;
  }

  applyDiscountCode(data: { discount_code: string }) {
    if (!this.hasToken()) {
      return;
    }
    this.saving$.next(true);

    return this.http.post(`/cart/${this.token}/discount-codes`, data).pipe(finalize(() => this.saving$.next(false)));
  }

  deleteDiscountCode() {
    if (!this.hasToken()) {
      return;
    }
    this.loading$.next(true);

    return this.http.delete(`/cart/${this.token}/discount-codes`).pipe(finalize(() => this.loading$.next(false)));
  }
}

const cartTokenKey = 'cart_token';

export interface Cart {
  currency: string;
  gross_amount: string;
  net_amount: string;
  vat_amount: string;
  token: string;
  items: CartItem[];
  discount_code: DiscountCode;
}

export interface CartItem {
  gross_amount: string;
  net_amount: string;
  vat_amount: string;
  parcel: ParcelDetail;
  product: Product;
}

export enum PaymentGateway {
  PRZELEWY24 = 'Przelewy24',
}

export enum ProductType {
  REPORT = 'report',
  ALERT = 'alert',
}

export interface Product {
  description: string;
  name: string;
  type: ProductType;
}

export interface DiscountCode {
  id: number;
  code: string;
  value: number;
  expires_at: string | Date;
  limit: number;
}
