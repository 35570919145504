<mat-form-field [formGroup]="searchInput" appearance="outline" class="reset-outline-padding">
  <mat-label>{{ label }}</mat-label>
  <input
    [value]="selected?.name"
    autocomplete="off"
    formControlName="s"
    id="report-search"
    matInput
    placeholder="Wpisz nr ewidencyjny lub ulicę, nr i pełną nazwę gminy"
  />
  <mat-icon
    *ngIf="!searchInput.controls.s.value.length"
    [color]="icon.color"
    [name]="icon.name"
    appSvgIcon
    matSuffix
  ></mat-icon>
  <mat-icon
    (click)="clearSearchInput()"
    *ngIf="searchInput.controls.s.value.length"
    [color]="icon.color"
    [name]="'Interface/Solid/cross-ui'"
    appSvgIcon
    matSuffix
  ></mat-icon>
</mat-form-field>

<div *ngIf="showResults" class="search-dropdown">
  <div class="parcels">
    <h6>Działki</h6>
    <app-loader *ngIf="parcels.loading$ | async" [isLoading]="parcels.loading$ | async"></app-loader>
    <ul *ngIf="parcels.data$ | async as items">
      <li
        (click)="select(item); showParcelDetail(item.id)"
        *ngFor="let item of items; let i = index"
        [class.hidden]="i > 3 && parcels.hidden"
      >
        <div *ngIf="i === 3 && parcels.hidden; else result" class="load-more">
          <button (click)="$event.stopPropagation(); showMoreParcels()" color="primary" mat-button size="small">
            Załaduj więcej wyników
          </button>
        </div>
        <ng-template #result>
          <div fxLayout="row" (click)="navigateTo(item.id)">
            <i appSvgIcon fill="accent" name="_Custom/area-marker" size="regular"></i>
            <span class="name">{{ item.name }} </span>
            <span class="number">{{ item.identifier }}</span>
          </div>
        </ng-template>
      </li>
      <li *ngIf="parcels.noResults$ | async" class="no-results">Brak wyników</li>
    </ul>
  </div>

  <div *ngIf="SearchContext.MAP === context || SearchContext.CART === context" class="communes">
    <h6>Gminy</h6>
    <app-loader *ngIf="communes.loading$ | async" [isLoading]="communes.loading$ | async"></app-loader>
    <ul *ngIf="communes.data$ | async as items">
      <li
        (click)="select(item); searchCommunes(item)"
        *ngFor="let item of items; let i = index"
        [class.hidden]="i > 3 && communes.hidden"
      >
        <div *ngIf="i === 3 && communes.hidden; else result" class="load-more">
          <button (click)="$event.stopPropagation(); showMoreCommunes()" color="primary" mat-button size="small">
            Załaduj więcej wyników
          </button>
        </div>
        <ng-template #result>
          <i appSvgIcon fill="accent" name="_Custom/area-marker" size="regular"></i>
          <span class="name">{{ item.name }} </span>
          <span class="number">{{ item.identifier }}</span>
        </ng-template>
      </li>
      <li *ngIf="communes.noResults$ | async" class="no-results">Brak wyników</li>
    </ul>
  </div>

  <div *ngIf="SearchContext.MAP === context || SearchContext.CART === context" class="points">
    <h6>Punkt adresowy</h6>
    <app-loader *ngIf="buildings.loading$ | async" [isLoading]="buildings.loading$ | async"></app-loader>
    <ul *ngIf="buildings.data$ | async as items">
      <li
        (click)="select(item); showParcelDetailFromBuilding(item)"
        *ngFor="let item of items; let i = index"
        [class.hidden]="i > 3 && buildings.hidden"
      >
        <div *ngIf="i === 3 && buildings.hidden; else result" class="load-more">
          <button (click)="$event.stopPropagation(); showMoreBuildings()" color="primary" mat-button size="small">
            Załaduj więcej wyników
          </button>
        </div>
        <ng-template #result>
          <i appSvgIcon fill="accent" name="_Custom/area-marker" size="regular"></i>
          <span class="name">{{ item.name }} </span>
          <span class="number">{{ item.identifier }}</span>
        </ng-template>
      </li>
      <li *ngIf="buildings.noResults$ | async" class="no-results">Brak wyników</li>
    </ul>
  </div>
</div>
