import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Logger } from '@app/@core/logger.service';
import { CredentialsService } from '@core/auth/credentials.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const logger = new Logger('ApiPrefixInterceptor');

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const credentialsService: CredentialsService = this.injector.get(CredentialsService);
    if (credentialsService.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${credentialsService.credentials.access_token}`,
        },
      });
    }
    let apiLoggerFlag = false;
    if (
      !/^(http|https):/i.test(request.url) &&
      request.url.indexOf(environment.assetsUrl) === -1 &&
      request.url.indexOf('oauth') === -1
    ) {
      request = request.clone({ url: `${environment.serverUrl}${request.url}` });
      apiLoggerFlag = true;
    }
    if (!/^(http|https):/i.test(request.url) && request.url.indexOf('oauth') > -1) {
      request = request.clone({ url: environment.authUrl + request.url });
      apiLoggerFlag = true;
    }
    if (apiLoggerFlag) {
      logger.debug('REQ:', request);
    }
    request = request.clone({
      setHeaders: {
        'Content-Language': 'pl-PL',
      },
    });
    return next.handle(request).pipe(
      tap((r) => {
        if (r instanceof HttpResponse && apiLoggerFlag) {
          logger.debug('RES:', r);
        }
      })
    );
  }
}
