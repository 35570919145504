import { Model, ModelFactory } from '@angular-extensions/model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResource } from '@app/@shared/interface/data-resource';
import { CartService } from '@app/routes/cart/models/cart/cart.service';
import { Credentials, CredentialsService } from '@core/auth/credentials.service';
import { EchoService } from '@core/echo.service';
import { Logger } from '@core/logger.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';

const logger = new Logger('CurrentUserService');

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUser$: Observable<CurrentUser>;
  loading$ = new BehaviorSubject(false);
  isAuthenticated$: Observable<boolean>;
  private model: Model<CurrentUser>;

  constructor(
    private modelFactory: ModelFactory<CurrentUser>,
    private http: HttpClient,
    private credentialsService: CredentialsService,
    private echoService: EchoService,
    private cartService: CartService
  ) {
    this.model = this.modelFactory.create(null);
    this.currentUser$ = this.model.data$;
    this.isAuthenticated$ = this.credentialsService.isAuthenticated$.asObservable();

    this.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        const admin = this.credentialsService.credentials.provider === 'admins';
        this.http
          .get(admin ? '/admin/me' : '/users/me')
          .pipe(map((r: { message?: string; data: CurrentUser }) => r.data))
          .subscribe((u: CurrentUser) => {
            u.is_admin = admin;
            this.model.set(u);
          });
      } else {
        this.model.set(null);
      }
    });
    this.currentUser$.pipe(filter((c) => !!c)).subscribe((u) => {
      logger.error(u);
      this.connectToWebsocket();
    });
  }

  // updateProp(newPropValue: string) {
  //   const currentUser = this.model.get();
  //
  //   currentUser.prop = newPropValue;
  //
  //   this.model.set(currentUser);
  // }
  login(body: OauthTokenRequest) {
    body.provider = 'users';
    body.grant_type = 'password';
    body.client_id = environment.clientId;
    body.client_secret = environment.clientSecret;
    this.loading$.next(true);
    return this.http.post(`/oauth/token`, body).pipe(
      finalize(() => this.loading$.next(false)),
      switchMap((cred: Credentials) => {
        cred.provider = 'users';
        this.credentialsService.setCredentials(cred);
        this.connectToWebsocket();
        const admin = this.credentialsService.credentials.provider === 'admins';
        return this.http.get(admin ? '/admin/me' : '/users/me').pipe(
          map((r: { message?: string; data: CurrentUser }) => {
            r.data.is_admin = admin;
            this.model.set(r.data);
            return r.data;
          })
        );
      })
    );
  }
  adminLogin(body: OauthTokenRequest) {
    body.provider = 'admins';
    body.grant_type = 'password';
    body.client_id = environment.clientId;
    body.client_secret = environment.clientSecret;
    this.loading$.next(true);
    return this.http.post(`/oauth/token`, body).pipe(
      finalize(() => this.loading$.next(false)),
      switchMap((cred: Credentials) => {
        cred.provider = 'admins';
        this.credentialsService.setCredentials(cred);
        this.connectToWebsocket();
        return this.http.get('/admin/me').pipe(map((r: { message?: string; data: CurrentUser }) => r.data));
      }),
      map((u: CurrentUser) => {
        u.is_admin = true;
        this.model.set(u);
        return u;
      })
    );
  }

  logout() {
    const admin = this.credentialsService.credentials.provider === 'admins';
    return this.http.post(admin ? '/admin/me/logout' : '/users/me/logout', {}).pipe(
      tap(() => {
        this.credentialsService.setCredentials(null);
        this.model.set(null);
        this.cartService.setToken(null);
        this.connectToWebsocket();
      })
    );
  }

  remindPassword(body: RemindPasswordRequest) {
    logger.info(body);
    this.loading$.next(true);
    return this.http.post('/auth/forgot-password', body).pipe(
      finalize(() => this.loading$.next(false)),
      map((r) => {
        logger.info(r);
      })
    );
  }

  updatePassword(body: UpdatePassswordRequest) {
    logger.info(body);
    this.loading$.next(true);
    return this.http.post('/auth/reset-password', body).pipe(
      finalize(() => this.loading$.next(false)),
      map((r) => {
        logger.info(r);
      })
    );
  }

  register(body: RegisterRequest) {
    logger.info(body);
    this.loading$.next(true);
    return this.http.post('/auth/register', body).pipe(
      finalize(() => this.loading$.next(false)),
      map((r) => {
        logger.info(r);
      })
    );
  }

  delete(body: DeleteAccountRequest) {
    this.loading$.next(true);
    return this.http
      .request('delete', '/users/me', {
        observe: 'body',
        body,
      })
      .pipe(
        tap(() => {
          this.credentialsService.setCredentials(null);
          this.model.set(null);
          this.connectToWebsocket();
        }),
        finalize(() => this.loading$.next(false)),
        map((r) => r)
      );
  }

  update(body: any): Observable<DataResource<Customer>> {
    this.loading$.next(true);
    return this.http.patch('/users/me/customer', body).pipe(
      finalize(() => this.loading$.next(false)),
      map((r: DataResource<Customer>) => {
        logger.info(r);
        return r;
      })
    );
  }

  private connectToWebsocket(): void {
    // Connect to websocket as non authorized
    const token = this.credentialsService.credentials ? this.credentialsService.credentials.access_token : null;
    this.echoService.connect(token);
  }
}

export interface CurrentUser {
  name: string;
  email: string;
  is_commune: boolean;
  is_admin: boolean;
  customer: Customer;
  // notifications: UserNotification[];
}

export interface OauthTokenRequest {
  grant_type: 'password' | 'refresh_token';
  client_id: number;
  client_secret: string;
  username?: 'email';
  password?: 'password';
  provider?: 'admins' | 'users';
  refresh_token?: string;
}

export interface RemindPasswordRequest {
  email: string;
}

export interface UpdatePassswordRequest {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
  password_confirmation: string;
}

export interface DeleteAccountRequest {
  current_password: string;
  delete_and_forget: boolean;
}

export interface Customer {
  phone_number: string;
  name: string;
  last_name: string;
  city: string;
  zip_code: string;
  street: string;
  nip: string;
  regon: string;
}
