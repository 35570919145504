import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchResource } from '@app/@shared/interface/search-resource';
import { Logger } from '@core';
import { BuildingDetail, BuildingDetailService } from '@shared/models/building-detail/building-detail.service';
import { Building, BuildingService } from '@shared/models/building/building.service';
import { Commune, CommuneService } from '@shared/models/commune/commune.service';
import { Parcel, ParcelService } from '@shared/models/parcel/parcel.service';
import { assign, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

const logger = new Logger('SearchComponent');

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  showResults = false;
  searchInput: FormGroup;
  searchForm: FormGroup;
  parcels: SearchResultSection<Parcel[]>;
  communes: SearchResultSection<Commune[]>;
  buildings: SearchResultSection<Building[]>;
  selected: SearchResource;
  SearchContext = SearchContext;
  icon: SearchIcon;
  @Input() context: SearchContext.MAP | SearchContext.LANDING | SearchContext.ADMIN | SearchContext.CART;
  @Input() label: string;
  @Input() isAccountNav: boolean;
  @Output() change: EventEmitter<SearchResource> = new EventEmitter();

  constructor(
    private parcelService: ParcelService,
    private communeService: CommuneService,
    private buildingService: BuildingService,
    private buildingDetailService: BuildingDetailService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.searchInput = this.fb.group({
      s: [''],
    });
  }

  ngOnInit(): void {
    this.parcels = {
      data$: this.parcelService.data$,
      loading$: this.parcelService.loading$,
      noResults$: this.parcelService.noResults$,
      hidden: true,
    };
    if (SearchContext.MAP === this.context || SearchContext.CART === this.context) {
      this.communes = {
        data$: this.communeService.data$,
        loading$: this.communeService.loading$,
        noResults$: this.communeService.noResults$,
        hidden: true,
      };
      this.buildings = {
        data$: this.buildingService.data$,
        loading$: this.buildingService.loading$,
        noResults$: this.buildingService.noResults$,
        hidden: true,
      };
    }
    this.icon = {
      name:
        SearchContext.MAP === this.context || SearchContext.ADMIN === this.context
          ? 'Interface/Outline/search'
          : 'Travel/Outline/location',
      color: SearchContext.MAP === this.context ? 'primary' : 'accent',
    };
    this.searchInput.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
      this.selected = null;
      this.change.emit(null);
      if (!this.searchInput.value.s) {
        this.showResults = false;
        return;
      }
      this.showResults = true;
      this.parcels.hidden = true;
      this.parcelService.search(
        new HttpParams({
          fromObject: assign({ type: SearchResultType.PARCEL }, v, (o: {}) => pickBy(o)),
        })
      );
      if (SearchContext.MAP === this.context || SearchContext.CART === this.context) {
        this.communes.hidden = true;
        this.communeService.search(
          new HttpParams({
            fromObject: assign({ type: SearchResultType.COMMUNE }, v, (o: {}) => pickBy(o)),
          })
        );
        this.buildings.hidden = true;
        this.buildingService.search(
          new HttpParams({
            fromObject: assign({ type: SearchResultType.BUILDING }, v, (o: {}) => pickBy(o)),
          })
        );
      }
    });
  }

  select(item: SearchResource) {
    this.selected = item;
    this.change.emit(item);

    this.showResults = false;
  }

  searchCommunes(item: SearchResource): void {
    if (this.isAccountNav) {
      this.showResults = false;
      this.router.navigate([`home`, { communeId: item.id }]);
    }
    this.communeService.searchById(item.id);
  }

  showParcelDetail(parcelId: number) {
    this.showResults = false;
    if (SearchContext.MAP === this.context || SearchContext.CART === this.context) {
      this.router.navigate([`/home/detail/${parcelId}`]);
    }
  }

  clearSearchInput() {
    this.searchInput.controls.s.setValue('');
    this.communeService.resetCommune();
  }

  showParcelDetailFromBuilding(building: Building) {
    this.buildingDetailService
      .getBuilding(building.id)
      .pipe(
        filter((data: BuildingDetail) => data.properties.parcel_ids.length > 0),
        map((data: BuildingDetail) => data.properties.parcel_ids[0])
      )
      .subscribe((id: number) => {
        this.showParcelDetail(id);
      });
  }

  showMoreParcels() {
    this.parcels.hidden = false;
  }

  showMoreCommunes() {
    this.communes.hidden = false;
  }

  showMoreBuildings() {
    this.buildings.hidden = false;
  }

  navigateTo(id: number): void {
    window.open(`https://mapa.osiadanieterenu.pl/home/detail/${id}`);
  }
}

export enum SearchResultType {
  BUILDING = 'building',
  COMMUNE = 'commune',
  PARCEL = 'parcel',
  ALL = 'all',
}

export interface SearchResultSection<T> {
  data$: Observable<T>;
  loading$: Observable<boolean>;
  noResults$?: Observable<boolean>;
  hidden?: boolean;
}

export enum SearchContext {
  MAP = 'map',
  LANDING = 'landing',
  ADMIN = 'admin',
  CART = 'cart',
}

export interface SearchIcon {
  name: string;
  color: string;
}
