import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, flatMap, retry, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { Credentials, CredentialsService } from '@core/auth/credentials.service';
import { CurrentUserService, OauthTokenRequest } from '@app/@core/auth/current-user/current-user.service';
import { EchoService } from '@core/echo.service';

const logger = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private injector: Injector) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const credentialsService = this.injector.get(CredentialsService);
    const echoService = this.injector.get(EchoService);
    const http = this.injector.get(HttpClient);
    return next.handle(request).pipe(
      catchError((error, oE) => {
        logger.error(error.status);
        if (error.status === 401 && !this.refreshTokenInProgress) {
          this.refreshTokenInProgress = true;
          const body: OauthTokenRequest = {
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            grant_type: 'refresh_token',
            refresh_token: credentialsService.credentials.refresh_token,
            provider: credentialsService.credentials.provider ? credentialsService.credentials.provider : null,
          };
          logger.info('REFRESH', 'START');
          return http.post(`/oauth/token`, body).pipe(
            flatMap((r: Credentials) => {
              logger.info('REFRESH', r);
              r.provider = body.provider;
              credentialsService.setCredentials(r);
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${r.access_token}`,
                },
              });
              this.refreshTokenInProgress = false;
              echoService.connect(r.access_token);
              return next.handle(request).pipe(retry(2));
            })
          );
        } else {
          return next
            .handle(request) // redirect stream while updating
            .pipe(
              delay(500),
              // retry(6),
              switchMap((e) => {
                return catchError((err, o) => {
                  this.errorHandler(err);
                  return o;
                });
              })
            );
        }
      })
    );
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      logger.error('Request error', response);
    }
    throw response;
  }
}
