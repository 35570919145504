import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
// @ts-ignore
import io from 'socket.io-client';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EchoService {
  static eventsToListen: EventToListen[] = [];

  echo: Echo;

  constructor() {}

  connect(bearerToken?: string) {
    if (this.echo) {
      this.echo.disconnect();
    }
    const options = {
      client: io,
      broadcaster: 'socket.io',
      host: environment.echoHost + ':' + environment.echoPort,
      auth: bearerToken ? { headers: { Authorization: 'Bearer ' + bearerToken } } : null,
    };

    this.echo = new Echo(options);

    // Listen added events
    this.listenEvents();
  }

  listen(eventToListen: EventToListen, callback: (element: any) => void) {
    this.addEventToListen(eventToListen, callback);

    if (eventToListen.channel.type === ChannelType.PRIVATE) {
      this.echo.private(eventToListen.channel.name).listen('.' + eventToListen.event, callback);

      return;
    }
    this.echo.channel(eventToListen.channel.name).listen('.' + eventToListen.event, callback);
  }

  private addEventToListen(eventToListen: EventToListen, callback: (element: any) => void) {
    const find: EventToListen = EchoService.eventsToListen.find(
      (el) => el.event === eventToListen.event && el.channel === eventToListen.channel
    );
    if (find) {
      return;
    }

    eventToListen.callback = callback;

    EchoService.eventsToListen.push({ ...eventToListen });
  }

  private listenEvents() {
    for (const eventToListen of EchoService.eventsToListen) {
      this.listen(eventToListen, eventToListen.callback);
    }
  }
}

export interface Channel {
  type: ChannelType;
  name: string;
}

export interface EventToListen {
  channel: Channel;
  event: string;
  callback?: (element: any) => void;
}

export enum ChannelType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}
