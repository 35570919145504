import { Model, ModelFactory } from '@angular-extensions/model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DataResource } from '@app/@shared/interface/data-resource';
import { SearchResource } from '@app/@shared/interface/search-resource';
import { MapSharingService } from '@app/routes/home/map-sharing.service';
import { Logger } from '@core';
import { GeoJsonObject } from 'geojson';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

const logger = new Logger('CommuneService');

@Injectable({
  providedIn: 'root',
})
export class CommuneService {
  data$ = new BehaviorSubject<Commune[]>(null);
  loading$ = new BehaviorSubject(true);
  noResults$ = new BehaviorSubject(false);
  mapSharingService: MapSharingService;

  private model: Model<Commune[]>;

  constructor(private modelFactory: ModelFactory<Commune[]>, private http: HttpClient, private injector: Injector) {
    this.mapSharingService = injector.get(MapSharingService);
    this.model = this.modelFactory.create(null);
  }

  search(params: HttpParams) {
    this.loading$.next(true);
    this.noResults$.next(false);
    this.data$.next(null);
    return this.http
      .get('/search', { params })
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe((r: DataResource<Commune>) => {
        this.model.set(r.data);
        this.data$.next(r.data);
        if (!r.data.length) {
          this.noResults$.next(true);
        }
      });
  }

  searchById(communeId: number): void {
    this.http
      .get<GeoJsonObject>(`/communes/${communeId}`, { params: { epsg: '4326' } })
      .subscribe((communeGeoJson: GeoJsonObject) => {
        this.mapSharingService.navigateToCoordinate(communeGeoJson);
      });
  }

  resetCommune() {
    this.mapSharingService.selectedCommuneLayer.clearLayers();
  }
}

export interface Commune extends SearchResource {}
